<template>
  <div class="AddClient">
    <CModal
      :show.sync="isClientCreated"
      :no-close-on-backdrop="true"
      title="Client créé"
      size="lg"
      color="dark"
    >
      <p class="text-center" style="font-size: 1.2em; font-weight: bold;">
        Votre client a bien été ajouté 👍
      </p>
      <p>
        Tous les collaborateurs de votre cabinet pourront maintenant accéder aux informations de votre client, et créer des missions ou des contacts liés à ce client.
      </p>
      <template #footer>
        <CButton @click="$router.push('/client-details/' + clientCreatedId)" color="primary">Accéder au profil de votre client</CButton>
        <CButton @click="$router.push('/manage-clients')" color="dark">Retour à la liste des clients</CButton>
      </template>
    </CModal>


    <loading
      :active.sync="isConflitPreventingLoading"
      :can-cancel="false"
      :is-full-page="true"
      color="#B40404">
      <div class="after-icon mt-3" slot="after">
        <strong>Prévention des confilts d'intérêts et duplicata</strong>
      </div>
    </loading>

    <CRow>
      <CCol>
        <h1> Ajouter un nouveau client </h1>
      </CCol>
    </CRow>
    <CCard class="mt-3">
      <CCardBody>
        <CRow class="mb-2">
          <CCol sm="2">
            Votre client est
          </CCol>
          <CCol sm="10">
            <CInputRadioGroup
              :checked.sync="newClientIsFirm"
              label="Votre client est"
              :custom="true"
              :inline="true"
              :options='clientIsFirmArrayForm'
            />
          </CCol>
        </CRow>
        <div v-if="newClientIsFirm =='true'">
          <CRow>
            <CCol sm="6">
              <CInput
                label="Nom du client"
                autocomplete="eclerk-no-autocomplete-input"
                v-model="newClientName" type="text"
                maxlength="150"
                placeholder="Nom"
                @change="searchClientByName(); searchMissionByContreClientName()"
                @input="$v.newClientName.$touch()"
                :isValid="$v.newClientName.$dirty ? !$v.newClientName.$error : null"
                invalid-feedback="Le nom doit comporter entre 2 et 150 caractères"
              >
              </CInput>

            </CCol>
            <CCol sm="6">
              <CInput
                label="Siren"
                autocomplete="eclerk-no-autocomplete-input"
                v-model="newClientSiren" type="text"
                v-mask="'NNN NNN NNN'"
                maxlength="11"
                placeholder="XXX XXX XXX"
                @change="searchClientBySiren(); searchMissionByContreClientSiren()"
                @input="$v.newClientSiren.$touch()"
                :isValid="$v.newClientSiren.$dirty ? !$v.newClientSiren.$error : null"
                invalid-feedback="Le numéro de SIREN doit être composé de 9 chiffres"
              >
              </CInput>
            </CCol>
          </CRow>

          <CRow v-if="!$v.newClientSiren.$invalid && missionsContreClientSiren.length > 0 && missionsContreClientSirenHasBeenSearched">
            <CCol class="text-danger">
              <strong><CIcon name="cil-warning"/> Prévention des conflits d'intérêts :</strong> ce siren appartient à la partie adverse d'une mission de votre cabinet :
              <ul class="text-dark">
                <li v-for="mission in missionsContreClientSiren" v-bind:key="mission.id">
                  {{mission.client_name}} - {{mission.name}} <em>(Référent : {{mission.referent_full_name}})</em>
                </li>
              </ul>
            </CCol>
          </CRow>
          <CRow class="mb-1" v-if="!$v.newClientSiren.$invalid && missionsContreClientSiren.length == 0 && missionsContreClientSirenHasBeenSearched">
            <CCol class="text-success">
              <strong><CIcon name="cil-check"/> Prévention des conflits d'intérêts :</strong> ce siren n'appartient à aucune partie adverse.
            </CCol>
          </CRow>

          <CRow v-if="!$v.newClientSiren.$invalid && duplicateClientsSiren.length > 0 && duplicateClientsSirenHasBeenSearched">
            <CCol class="text-danger">
              <strong><CIcon name="cil-warning"/> Prévention des doublons :</strong> ce siren appartient déjà un client :
              <ul class="text-dark">
                <li v-for="duplicateClient in duplicateClientsSiren" v-bind:key="duplicateClient.id">
                  {{duplicateClient.name}} <router-link :to="'/client-details/' + String(duplicateClient.id)" target="_blank">Accéder au profil client</router-link>
                </li>
              </ul>
            </CCol>
          </CRow>
          <CRow class="mb-3" v-if="!$v.newClientSiren.$invalid && duplicateClientsSiren.length == 0 && duplicateClientsSirenHasBeenSearched">
            <CCol class="text-success">
              <strong><CIcon name="cil-check"/> Prévention des doublons :</strong> ce siren n'appartient à aucun client de votre cabinet.
            </CCol>
          </CRow>




          <CRow v-if="!$v.newClientName.$invalid && missionsContreClientName.length > 0 && missionsContreClientNameHasBeenSearched">
            <CCol class="text-danger">
              <strong><CIcon name="cil-warning"/> Prévention des conflits d'intérêts :</strong> ce nom correspond au nom de la partie adverse d'une mission de votre cabinet :
              <ul class="text-dark">
                <li v-for="mission in missionsContreClientName" v-bind:key="mission.id">
                  {{mission.client_name}} - {{mission.name}} <em>(Référent : {{mission.referent_full_name}})</em>
                </li>
              </ul>
            </CCol>
          </CRow>

          <CRow v-if="!$v.newClientName.$invalid && duplicateClientsName.length > 0 && duplicateClientsNameHasBeenSearched">
            <CCol class="text-danger">
              <strong><CIcon name="cil-warning"/> Prévention des doublons :</strong> ce nom correspond au nom d'un client de votre cabinet :
              <ul class="text-dark">
                <li v-for="duplicateClient in duplicateClientsName" v-bind:key="duplicateClient.id">
                  {{duplicateClient.name}} <router-link :to="'/client-details/' + String(duplicateClient.id)" target="_blank">Accéder au profil client</router-link>
                </li>
              </ul>
            </CCol>
          </CRow>

          <CRow>
            <CCol sm="6">
              <CInput
                label="Représentant légal"
                autocomplete="eclerk-no-autocomplete-input"
                v-model="newClientRepresentantLegal" type="text"
                maxlength="150"
                placeholder="Nom du représentant légal"
                @input="$v.newClientRepresentantLegal.$touch()"
                :isValid="$v.newClientRepresentantLegal.$dirty ? !$v.newClientRepresentantLegal.$error : null"
                invalid-feedback="Le nom doit comporter entre 2 et 150 caractères"
                >
              </CInput>
            </CCol>
            <CCol sm="6">
              <span> Forme sociale</span>
              <vue-bootstrap-typeahead
                class="mt-2"
                v-model="newClientFormeSociale"
                :data="newClientFormeSocialeArrayOptions"
                @hit="newClientFormeSociale = $event"
                @input="$v.newClientFormeSociale.$touch()"
                :isValid="$v.newClientFormeSociale.$dirty ? !$v.newClientFormeSociale.$error : null"
                placeholder="Forme sociale"
              />
            </CCol>
          </Crow>
        </div>

        <div v-if="newClientIsFirm =='false'">
          <CRow >
            <CCol sm="6">
              <CInput
                label="Prénom du client"
                autocomplete="eclerk-no-autocomplete-input"
                v-model="newClientFirstName" type="text"
                maxlength="150"
                placeholder="Prénom"
                @input="$v.newClientFirstName.$touch()"
                :isValid="$v.newClientFirstName.$dirty ? !$v.newClientFirstName.$error : null"
                invalid-feedback="Le prénom doit comporter entre 2 et 150 caractères"
              >
              </CInput>
            </CCol>
            <CCol sm="6">
              <CInput
                label="Nom du client"
                autocomplete="eclerk-no-autocomplete-input"
                v-model="newClientLastName" type="text"
                maxlength="150"
                placeholder="Nom"
                @change="searchClientByLastName(); searchMissionByContreClientLastName()"
                @input="$v.newClientLastName.$touch()"
                :isValid="$v.newClientLastName.$dirty ? !$v.newClientLastName.$error : null"
                invalid-feedback="Le nom doit comporter entre 2 et 150 caractères"
              >
              </CInput>
            </CCol>
          </CRow>

          <CRow v-if="!$v.newClientLastName.$invalid && missionsContreClientLastName.length > 0 && missionsContreClientLastNameHasBeenSearched">
            <CCol class="text-danger">
              <strong><CIcon name="cil-warning"/> Prévention des conflits d'intérêts :</strong> ce nom correspond au nom de la partie adverse d'une mission de votre cabinet :
              <ul class="text-dark">
                <li v-for="mission in missionsContreClientLastName" v-bind:key="mission.id">
                  {{mission.client_name}} - {{mission.name}} <em>(Référent : {{mission.referent_full_name}})</em>
                </li>
              </ul>
            </CCol>
          </CRow>

          <CRow v-if="!$v.newClientLastName.$invalid && duplicateClientsLastName.length > 0 && duplicateClientsLastNameHasBeenSearched">
            <CCol class="text-danger">
              <strong><CIcon name="cil-warning"/> Prévention des doublons :</strong> ce nom correspond au nom d'un client de votre cabinet :
              <ul>
                <li v-for="duplicateClient in duplicateClientsLastName" v-bind:key="duplicateClient.id">
                  {{duplicateClient.name}} <router-link :to="'/client-details/' + String(duplicateClient.id)" target="_blank">Accéder au profil client</router-link>
                </li>
              </ul>
            </CCol>
          </CRow>

        </div>

        <CRow class="mb-2">
          <CCol sm="5">
            Souhaitez-vous affecter votre client à un contact déjà dans votre base ?
          </CCol>
          <CCol sm="7">
            <CInputRadioGroup
              :checked.sync="wantsToAddContact"
              :custom="true"
              :inline="true"
              :options='booleanArrayForm'
            />
          </CCol>
        </CRow>

        <div class="mb-2" v-if="wantsToAddContact == 'true'">
          <span> Votre contact choisi : </span>
          <strong v-if="newClientContactObject.id != ''"> {{newClientContactObject.full_name}} </strong>
          <strong v-else> Aucun </strong>

          <vue-bootstrap-typeahead
            class="mt-2"
            v-model="newClientContactName"
            :data="contacts"
            :serializer="item => item.full_name_email"
            @hit="newClientContactObject = $event"
             placeholder="Chercher votre contact par nom ou prénom"
          />
          <small class="text-muted">
            Si vous ne trouvez pas votre contact, cela signifie qu'il est déjà affecté à un autre client.
          </small>
        </div>

        <CRow class="mt-3">
          <CCol sm="6">
            <CInput
              label="Email (facultatif)"
              autocomplete="eclerk-no-autocomplete-input"
              type="email"
              maxlength="150"
              placeholder="Email (facultatif)"
              v-model="newClientEmail"
              @input="$v.newClientEmail.$touch()"
              :isValid="$v.newClientEmail.$dirty ? !$v.newClientEmail.$error : null"
            >
            </CInput>
          </CCol>
          <CCol sm="6">
            <div role="group" class="form-group">
              <label for="addClientVuePhoneNumber" class>
                Numéro de téléphone (facultatif)
              </label>
              <vue-phone-number-input
                id="addClientVuePhoneNumber"
                name="addClientVuePhoneNumber"
                v-model="newClientPhoneNumber"
                :default-country-code='newClientPhoneNumberResult.countryCode'
                :preferred-countries="['FR', 'BE', 'DE']"
                valid-color='#28a745'
                error-color='#dc3545'
                :translations="{
                  countrySelectorLabel: 'Code pays',
                  countrySelectorError: 'Choisir un pays',
                  phoneNumberLabel: 'Numéro de téléphone',
                  example: 'Exemple :'
                }"
                @update='newClientPhoneNumberResult = $event'
                :required='false'
              />
              <small class="text-danger" v-if="newClientPhoneNumberResult.isValid == false">Veuillez rentrer un numéro de téléphone valide</small>
            </div>
          </CCol>
        </CRow>

        <CRow class="mb-2">
          <CCol sm="3">
            L'adresse du client est une adresse :
          </CCol>
          <CCol sm="9">
            <CInputRadioGroup
              :checked.sync="newClientIsDomiciliation"
              :custom="true"
              :inline="true"
              :options='clientIsDomiciliationArrayForm'
            />
          </CCol>
        </CRow>

        <form autocomplete="eclerk-no-autocomplete-input">
          <label for="eclerk-new-client-address">Adresse du client</label>
          <vue-google-autocomplete
            autocomplete="eclerk-no-autocomplete-input"
            ref="eclerkNewClientAddress"
            id="eclerk-new-client-address"
            name="eclerk-new-client-address"
            classname="form-control"
            maxlength="299"
            placeholder="Rechercher l'adresse"
            @placechanged="chooseGoogleMapsNewClientAddress"
            @inputChange="newClientAddress = $event.newVal"
            :enable-geolocation='true'
          >
          </vue-google-autocomplete>
        </form>

      </CCardBody>
    </CCard>
    <CCard>
      <CCardBody>

        <CRow class="mb-3">
          <CCol sm="5">
            Le client est-il <strong>différent</strong> du  payeur ?
          </CCol>
          <CCol sm="7">
            <CInputRadioGroup
              :checked.sync="newClientIsDifferentPayeur"
              :custom="true"
              :inline="true"
              :options='booleanArrayForm'
            />
          </CCol>
        </CRow>

        <div v-if="newClientIsDifferentPayeur == 'true'">

          <CRow class="mb-2">
            <CCol sm="2">
              Le payeur est
            </CCol>
            <CCol sm="10">
              <CInputRadioGroup
                :checked.sync="newClientPayeurIsFirm"
                :custom="true"
                :inline="true"
                :options='clientIsFirmArrayForm'
              />
            </CCol>
          </CRow>

          <CRow v-if="newClientPayeurIsFirm =='true'">
            <CCol sm="6">
              <CInput
                label="Siren"
                autocomplete="eclerk-no-autocomplete-input"
                v-model="newClientPayeurSiren" type="text"
                maxlength="9"
                placeholder="XXXXXXXXX"
                @input="$v.newClientPayeurSiren.$touch()"
                :isValid="$v.newClientPayeurSiren.$dirty ? !$v.newClientPayeurSiren.$error : null"
                invalid-feedback="Le numéro de SIREN doit être composé de 9 chiffres"
              >
              </CInput>
            </CCol>
            <CCol sm="6">
              <CInput
              label="Représentant légal"
              autocomplete="eclerk-no-autocomplete-input"
              v-model="newClientPayeurRepresentantLegal" type="text"
              maxlength="150"
              placeholder="Nom du représentant légal"
              @input="$v.newClientPayeurRepresentantLegal.$touch()"
              :isValid="$v.newClientPayeurRepresentantLegal.$dirty ? !$v.newClientPayeurRepresentantLegal.$error : null"
              invalid-feedback="Le nom doit comporter entre 2 et 150 caractères"
              >
              </CInput>
            </CCol>
          </CRow>

          <CInput
            label="Nom du payeur"
            autocomplete="eclerk-no-autocomplete-input"
            v-model="newClientPayeurName" type="text"
            maxlength="150"
            placeholder="Nom"
            @input="$v.newClientPayeurName.$touch()"
            :isValid="$v.newClientPayeurName.$dirty ? !$v.newClientPayeurName.$error : null"
            invalid-feedback="Le nom doit comporter entre 2 et 150 caractères"
          >
          </CInput>

          <form autocomplete="eclerk-no-autocomplete-input">
            <label for="eclerk-new-client-payeur-address">Adresse du payeur</label>
            <vue-google-autocomplete
              autocomplete="eclerk-no-autocomplete-input"
              ref="eclerkNewClientAddress"
              id="eclerk-new-client-payeur-address"
              name="eclerk-new-client-payeur-address"
              classname="form-control"
              maxlength="299"
              placeholder="Rechercher l'adresse"
              @placechanged="chooseGoogleMapsNewClientPayeurAddress"
              @inputChange="newClientPayeurAddress = $event.newVal"
              :enable-geolocation='true'
            >
            </vue-google-autocomplete>
          </form>

        </div>

      </CCardBody>
    </CCard>

    <CRow class="mb-4">
      <CCol class="text-center">
        <CButton
          @click="createClient" :color="createClientButtonStyle"
          shape="pill" block class="px-4"
          size="lg"
          :disabled="isInvalidAddClient || createClientInProcess">
          <CSpinner size="sm" label="update profile spinner" v-if="createClientInProcess"></CSpinner>
          {{ createClientButtonText }}
        </CButton>
      </CCol>
    </CRow>

  </div>
</template>

<script>

import { validationMixin } from 'vuelidate'
import { required, minLength, maxLength, email, numeric, requiredIf} from 'vuelidate/lib/validators'
import { isStringIntegerOrBlank } from '@/validators/validators'

import { APIUserConnected } from '@/api/APIUserConnected'

import VueBootstrapTypeahead from 'vue-bootstrap-typeahead'
import VueGoogleAutocomplete from 'vue-google-autocomplete'

import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';

const apiUserConnected = new APIUserConnected()

export default {
  name: 'AddClient',
  components: {
    VueBootstrapTypeahead,
    VueGoogleAutocomplete,
    Loading
  },
  mixins: [
    validationMixin,
  ],
  data: function () {
    return {
      // ----- Create Client ---
      newClientIsFirm: 'true',
      newClientSiren: '',
      newClientFormeSociale: '',
      newClientRepresentantLegal: '',
      newClientName: '',
      newClientFirstName: '',
      newClientLastName: '',
      newClientEmail: '',
      newClientPhoneNumber: null,
      newClientPhoneNumberResult: {
        countryCode: "FR",
      },
      newClientIsDomiciliation: 'false',
      newClientAddress: '',

      newClientIsDifferentPayeur: 'false',
      newClientPayeurIsFirm: 'true',
      newClientPayeurSiren: '',
      newClientPayeurRepresentantLegal: '',
      newClientPayeurName: '',
      newClientPayeurAddress: '',

      wantsToAddContact: 'false',
      contacts: [],
      newClientContactName: '',
      newClientContactObject: {
        id: '',
        full_name: ''
      },

      createClientButtonText: 'Ajouter un nouveau client',
      createClientButtonStyle: 'outline-primary',
      createClientInProcess: false,

      clientIsFirmArrayForm: [
        { label: 'Une personne morale', value: 'true' },
        { label: "Une personne physique", value: 'false' },
      ],

      newClientFormeSocialeArrayOptions: [
        'Entreprise individuelle',
        "Entreprise unipersonnelle à responsabilité limitée",
        "Société à responsabilité limitée",
        "Société par actions simplifiée",
        "Société en nom collectif",
        "Société coopérative de production",
        "Société civile professionnelle",
      ],

      clientIsDomiciliationArrayForm: [
        { label: 'Privée', value: 'false' },
        { label: "De domiciliation", value: 'true' },
      ],

      isClientCreated: false,
      clientCreatedId: '',

      booleanArrayForm: [
        { label: 'Oui', value: 'true' },
        { label: "Non", value: 'false' },
      ],

      isSearchingMissionByContreClientSiren: false,
      missionsContreClientSirenHasBeenSearched: false,
      missionsContreClientSiren : [],

      isSearchingClientBySiren: false,
      duplicateClientsSirenHasBeenSearched: false,
      duplicateClientsSiren : [],

      isSearchingClientByName: false,
      duplicateClientsNameHasBeenSearched: false,
      duplicateClientsName : [],

      isSearchingMissionByContreClientName: false,
      missionsContreClientNameHasBeenSearched: false,
      missionsContreClientName : [],

      isSearchingClientByLastName: false,
      duplicateClientsLastNameHasBeenSearched: false,
      duplicateClientsLastName : [],

      isSearchingMissionByContreClientLastName: false,
      missionsContreClientLastNameHasBeenSearched: false,
      missionsContreClientLastName : [],

    }
  },
  computed: {
    token () {
      return this.$store.state.auth.token
    },
    isConflitPreventingLoading () {
      if (this.isSearchingClientBySiren || this.isSearchingClientByName || this.isSearchingClientByLastName || this.isSearchingMissionByContreClientSiren || this.isSearchingMissionByContreClientName || this.isSearchingMissionByContreClientLastName) {
        return true
      }
      return false
    },
    authHeaders () {
      return apiUserConnected.getAuthHeaders(this.token)
    },
    finalNewClientSiren () {
      return this.newClientSiren.replace(/\s/g,'');
    },
    isInvalidAddClient () {
      if (this.$v.newClientIsFirm.$invalid || this.$v.newClientSiren.$invalid || this .$v.newClientFormeSociale.$invalid ||
      this.$v.newClientName.$invalid || this.$v.newClientFirstName.$invalid || this.$v.newClientLastName.$invalid ||
      this.$v.newClientEmail.$invalid || this.$v.newClientAddress.$invalid ||
      this.$v.newClientPhoneNumberResult.$invalid || this.$v.newClientIsDifferentPayeur.$invalid || this.$v.newClientPayeurIsFirm.$invalid ||
      this.$v.newClientPayeurSiren.$invalid || this.$v.newClientPayeurRepresentantLegal.$invalid ||
      this.$v.newClientPayeurName.$invalid || this.$v.newClientPayeurAddress.$invalid ) {
        return true
      }
      return false
    },
  },
  validations: {
    newClientIsFirm: {
      required
    },
    newClientSiren: {
      minLength: minLength(11),
      maxLength: maxLength(11),
      validSiren(value) {
        return isStringIntegerOrBlank(value)
      },
      required: requiredIf(function () {
        return this.newClientIsFirm =='true'
      }),
    },
    newClientFormeSociale: {
      minLength: minLength(2),
      maxLength: maxLength(149),
    },
    newClientRepresentantLegal: {
      minLength: minLength(2),
      maxLength: maxLength(149),
      required: requiredIf(function () {
        return this.newClientIsFirm == 'true'
      }),
    },
    newClientName: {
      minLength: minLength(2),
      maxLength: maxLength(149),
      required: requiredIf(function () {
        return this.newClientIsFirm == 'true'
      }),
    },
    newClientFirstName: {
      minLength: minLength(2),
      maxLength: maxLength(149),
      required: requiredIf(function () {
        return this.newClientIsFirm == 'false'
      }),
    },
    newClientLastName: {
      minLength: minLength(2),
      maxLength: maxLength(149),
      required: requiredIf(function () {
        return this.newClientIsFirm == 'false'
      }),
    },
    newClientEmail: {
      email,
      minLength: minLength(2),
      maxLength: maxLength(249),
    },
    newClientPhoneNumberResult: {
      countryCode: {
        minLength: minLength(2),
        maxLength: maxLength(2)
      },
      isValid: {
        isValid(value) {
          if (this.newClientPhoneNumber == null) {
            return true
          }
          else {
            return value
          }
        }
      }
    },
    newClientIsDomiciliation: {
      required
    },
    newClientAddress: {
      required,
      minLength: minLength(2),
      maxLength: maxLength(300)
    },

    newClientIsDifferentPayeur: {
      required
    },
    newClientPayeurIsFirm: {
      required
    },
    newClientPayeurSiren: {
      minLength: minLength(9),
      maxLength: maxLength(9),
      numeric,
      required: requiredIf(function () {
        return this.newClientPayeurIsFirm =='true' && this.newClientIsDifferentPayeur == 'true'
      }),
    },
    newClientPayeurRepresentantLegal: {
      minLength: minLength(2),
      maxLength: maxLength(149),
      required: requiredIf(function () {
        return this.newClientPayeurIsFirm == 'true' && this.newClientIsDifferentPayeur == 'true'
      }),
    },
    newClientPayeurName: {
      maxLength: maxLength(149),
      minLength: minLength(2),
      required: requiredIf(function () {
        return this.newClientIsDifferentPayeur == 'true'
      }),
    },
    newClientPayeurAddress: {
      minLength: minLength(2),
      maxLength: maxLength(300),
      required: requiredIf(function () {
        return this.newClientIsDifferentPayeur == 'true'
      }),
    },

  },

  watch: {
    // When the query value changes, fetch new results from
    // the API - in practice this action should be debounced
    newClientContactName(newQuery) {
      if (newQuery == '') {
        this.contacts = []
        this.newClientContactObject = {
          id: '',
          full_name: ''
        }
      }
      else if (newQuery.length >= 2) {
        apiUserConnected.searchContactNotLinkedToClientByFirstOrLastName(this.token, newQuery)
        .then((res) => {
          this.contacts = res.data
        })
      }
    },
  },

  methods: {

    // ---------- Create client -----------
    createClient () {
      this.createClientButtonText = 'Création en cours'
      this.createClientButtonStyle = 'secondary'
      this.createClientInProcess = true
      apiUserConnected.addClient(this.token,
        this.newClientIsFirm, this.newClientContactObject.id, this.finalNewClientSiren, this.newClientFormeSociale, this.newClientRepresentantLegal,
        this.newClientName, this.newClientFirstName, this.newClientLastName, this.newClientEmail,
        this.newClientPhoneNumberResult.countryCode, this.newClientPhoneNumberResult.nationalNumber,
        this.newClientIsDomiciliation, this.newClientAddress, this.newClientIsDifferentPayeur, this.newClientPayeurIsFirm,
        this.newClientPayeurSiren, this.newClientPayeurRepresentantLegal, this.newClientPayeurName, this.newClientPayeurAddress)
      .then((result) => {
        this.isClientCreated = true
        this.clientCreatedId = result.data.id
        this.resetNewClient()
      })
      .catch(() => {
        this.$store.commit('openGlobalErrorModal')
      })
      .finally(() => {
        this.createClientButtonText = 'Ajouter un nouveau client'
        this.createClientButtonStyle = 'outline-primary'
        this.createClientInProcess = false
      })
    },

    resetNewClient() {
      this.newClientIsFirm = 'true'
      this.newClientContactName = ''
      this.newClientFirstName = ''
      this.newClientLastName = ''
      this.newClientContactObject = {
        id: '',
        name: ''
      }
      this.newClientSiren = ''
      this.newClientFormeSociale = ''
      this.newClientRepresentantLegal = ''
      this.newClientName = ''
      this.newClientEmail = ''
      this.newClientPhoneNumber =  null
      this.newClientPhoneNumberResult = {
        countryCode: 'FR'
      }
      this.newClientIsDomiciliation = 'false'
      this.newClientAddress = ''
      this.newClientIsDifferentPayeur = 'false'
      this.newClientPayeurIsFirm = ' false'
      this.newClientPayeurSiren = ''
      this.newClientRepresentantLegal = ''
      this.newClientName = ''
      this.newClientAddress = ''
    },

    chooseGoogleMapsNewClientAddress: function (addressData) {
      this.newClientAddress = addressData['street_number'] + ', ' + addressData['route'] + ', ' + addressData['locality'] + ', ' + addressData['country'];
    },

    chooseGoogleMapsNewClientPayeurAddress: function (addressData) {
      this.newClientPayeurAddress = addressData['street_number'] + ', ' + addressData['route'] + ', ' + addressData['locality'] + ', ' + addressData['country'];
    },



    searchClientBySiren () {
      if (this.$v.newClientSiren.$invalid) {
        return null
      }
      else {
        this.isSearchingClientBySiren = true
        apiUserConnected.searchClientBySiren(this.token, this.finalNewClientSiren)
        .then((result) => {
          this.duplicateClientsSiren = result.data
        })
        .catch(() => {
          this.$store.commit('openGlobalErrorModal')
        })
        .finally(() => {
          this.isSearchingClientBySiren = false
          this.duplicateClientsSirenHasBeenSearched = true
        })
      }
    },

    searchMissionByContreClientSiren () {
      if (this.$v.newClientSiren.$invalid) {
        return null
      }
      else {
        this.isSearchingMissionByContreClientSiren = true
        apiUserConnected.searchMissionByContreClientSiren(this.token, this.finalNewClientSiren)
        .then((result) => {
          this.missionsContreClientSiren = result.data
        })
        .catch(() => {
          this.$store.commit('openGlobalErrorModal')
        })
        .finally(() => {
          this.isSearchingMissionByContreClientSiren = false
          this.missionsContreClientSirenHasBeenSearched = true
        })
      }
    },

    searchClientByName () {
      if (this.$v.newClientName.$invalid) {
        return null
      }
      else {
        this.isSearchingClientByName = true
        apiUserConnected.searchClientByName(this.token, this.newClientName)
        .then((result) => {
          this.duplicateClientsName = result.data
        })
        .catch(() => {
          this.$store.commit('openGlobalErrorModal')
        })
        .finally(() => {
          this.isSearchingClientByName = false
          this.duplicateClientsNameHasBeenSearched = true
        })
      }
    },

    searchMissionByContreClientName () {
      if (this.$v.newClientName.$invalid) {
        return null
      }
      else {
        this.isSearchingMissionByContreClientName = true
        apiUserConnected.searchMissionByContreClientName(this.token, this.newClientName)
        .then((result) => {
          this.missionsContreClientName = result.data
        })
        .catch(() => {
          this.$store.commit('openGlobalErrorModal')
        })
        .finally(() => {
          this.isSearchingMissionByContreClientName = false
          this.missionsContreClientNameHasBeenSearched = true
        })
      }
    },

    searchClientByLastName () {
      if (this.$v.newClientLastName.$invalid) {
        return null
      }
      else {
        this.isSearchingClientByLastName = true
        apiUserConnected.searchClientByName(this.token, this.newClientLastName)
        .then((result) => {
          this.duplicateClientsLastName = result.data
        })
        .catch(() => {
          this.$store.commit('openGlobalErrorModal')
        })
        .finally(() => {
          this.isSearchingClientByLastName = false
          this.duplicateClientsLastNameHasBeenSearched = true
        })
      }
    },

    searchMissionByContreClientLastName () {
      if (this.$v.newClientLastName.$invalid) {
        return null
      }
      else {
        this.isSearchingMissionByContreClientLastName = true
        apiUserConnected.searchMissionByContreClientName(this.token, this.newClientLastName)
        .then((result) => {
          this.missionsContreClientLastName = result.data
        })
        .catch(() => {
          this.$store.commit('openGlobalErrorModal')
        })
        .finally(() => {
          this.isSearchingMissionByContreClientLastName = false
          this.missionsContreClientLastNameHasBeenSearched = true
        })
      }
    },
  }
}
</script>
<style>
.after-icon {
  position: absolute;
  left: -80%;
  width: 200px;
  color: #B40404;
}
</style>
